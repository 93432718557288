import axios from "axios";
import {
  SellMyCar,
  BuyMyCar,
  LicenceCheck,
  Location,
  BookRental,
  Login,
  Mileage,
  AccidentStage1,
  AccidentStage2,
  Profile,
  UpdateProfile,
  MyCar,
  updatePassword,
  TripData,
  TripUpdateData
} from "./CustomTypes";
import helper from "./Helpers";

let http: any = null;

class ApiProvider {
  constructor({ url }: any) {
    http = axios.create({
      baseURL: url,
      headers: { "Content-Type": "application/json" },
      validateStatus: () => true,
    });
  }

  async getCmsPage(slug: string | string[]) {
    let id = 0;
    switch (slug) {
      case "accident-guide":
        id = 2;
        break;
      case "efficient-driving":
        id = 5;
        break;
      case "what-to-do-in-an-accident":
        id = 6;
        break;
      case "winter-driving":
        id = 7;
        break;
      case "overseas-driving":
        id = 8;
        break;
      case "driver-support":
        id = 9;
        break;
      case "tch-salsa":
        id = 14;
        break;
      case "breakdown-recovery":
        id = 16;
        break;
      case "tyres-batteries-exhausts":
        id = 17;
        break;
      case "terms-conditions":
        id = 19;
        break;
      case "replacement-glass":
        id = 20;
        break;
      case "take-your-vehicle-abroad-1":
        id = 21;
        break;
      case "mot-booking":
        id = 22;
        break;
      case "service-booking":
        id = 23;
        break;
      case "take-your-vehicle-abroad":
        id = 24;
        break;
      default:
        break;
    }

    return http.get(`/pages/${id}`);
  }

  async sellMyCar(data: SellMyCar) {
    return http.get(
      `/tcbg_valuation/${data.reg}/${data.mileage ?? 0}/${data.name}/${
        data.email
      }/${data.phone}/${data.postcode}/${data.status}/${data.collectionDate}`
    );
  }

  async buyMyCar(data: BuyMyCar) {
    const userId = helper.userId;
    if (userId === undefined) {
      return false;
    }

    return http.post(`/buy-my-car/${userId}`, data);
  }

  async checkMyLicence(data: LicenceCheck) {
    return http.post("/license_check/notifiy", data);
  }

  async getKwikfitLocations(data: Location) {
    return http.post("/maplocations/kwikfit", {
      location: `${data.lat},${data.lng}`,
    });
  }

  async getChargeLocations(data: Location) {
    return http.post("/maplocations/chargestations", {
      location: `${data.lat},${data.lng}`,
    });
  }

  async getLocations(type: string, data: Location) {
    return http.post("/maplocations", {
      searchFor: type.replace("fit", ""),
      location: `${data.lat},${data.lng}`,
    });
  }

  async bookRental(data: BookRental) {
    const token = await helper.getToken();

    return http.post("/book_a_rentals", data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async attemptLogin(data: Login) {
    return http.post("/login_check", data);
  }

  async getMe(token: string) {
    return http.post("/me", token, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getCar(url: string, token: string) {
    return http.get(url.replace("/v1", ""), {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async updateMileage(data: Mileage, token: string) {
    return http.put(`/mycars/${data.id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getContractData(url: string, token: string) {
    return http.get(url.replace("/v1", ""), {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async reportAccidentStage1(data: AccidentStage1, token: string) {
    return http.post("/report_accidents", data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async reportAccidentStage2(data: AccidentStage2, token: string) {
    return http.post("/report_accident_other_cars", data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async reportAccidentComplete(id: string, token: string) {
    return http.post(
      "/report_accident_completed",
      { id: id },
      {
        headers: {
          authorization: `Bearer ${token}`,
          accept: "application/json",
        },
      }
    );
  }

  async createProfile(data: Profile) {
    return http.post("/register", data, {
      headers: {
        accept: "application/json",
      },
    });
  }

  async updateProfile(data: UpdateProfile, id:string, token:string) {
    return http.put(`/users/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async submitCarData(data: MyCar, token: string) {
    return http.post("/mycars", data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async submitCarDataUpdate(data: MyCar, id:string, token: string) {
    return http.put(`/mycars/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    });
  }

  async resetPassword(email: string) {
    return http.post("/reset-password", { email: email }, {
      headers: {
        accept: "application/json",
      },
    });
  }

  async updatePassword(data: updatePassword, id:string, token: string) {
    return http.put(`/change-password/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    });
  }

  async saveTrip(data: TripData, token: string) {
    return http.post("/trip_counters", data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    })
  }

  async getTrips(user: string, token: string) {
    return http.get(`/trip_counters?user=${user}`, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async updateTripDistance(data: TripUpdateData, token: string) {
    return http.put(`/trip_counters/${data.id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    });
  }

  async getP11dCarMakes(token: string) {
    return http.get('/get_car_p11d', {
       headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getP11dCarModelsByMake(make:string, token:string) {
    return http.get(`/get_car_p11d/${make}`, {
       headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getP11dCarTrimsByModel(make:string, model:string, token:string) {
    return http.get(`/get_car_p11d/${make}/${model}`, {
       headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getP11dValue(make:string, model:string, trim:string, token:string) {
    return http.get(`/get_car_p11d/${make}/${model}/${trim}`, {
       headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async getTaxCarMakes() {
    return http.get('/tax_makes?order%5Bmake%5D=asc', {
       headers: {
        accept: "application/json",
      },
    });
  }

  async getTaxCarModelsByMake(makeid:number) {
    return http.get(`/tax_models?order%5Bmodel%5D=asc&make=${makeid}`, {
       headers: {
        accept: "application/json",
      },
    });
  }

  async getTaxCarsByModel(modelid:number) {
    return http.get(`/tax_vehicles?order%5Btrim%5D=asc&model=${modelid}`, {
       headers: {
        accept: "application/json",
      },
    });
  }

  async getVehicleById(vehicleid:number) {
    return http.get(`/tax_vehicles/${vehicleid}`, {
       headers: {
        accept: "application/json",
      },
    });
  }

  async getTaxesByVehicle(vehicleid:number) {
    return http.get(`/tax_details?order%5Byearlabel%5D=asc&vehicle=${vehicleid}`, {
       headers: {
        accept: "application/json",
      },
    });
  }

  async genericGet(url:string) {
    return http.get(url, {
       headers: {
        accept: "application/json",
      },
    });
  }

  async getChecklistData(vehicleid:number, token:string, categoryId = 0) {
    return http.get(`/completed_checklists/mycar/${vehicleid}/category/${categoryId}`, {
       headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    });
  }

  async updateChecklistQuestion(qId: number, state: boolean, vehicleId: number, token: string) {
    return http.post(`/completed_checklists/mycar/${vehicleId}/question/${qId}`, { qId, state }, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    })
  }
}

export default new ApiProvider({
  url: 'https://tchsalsa.dev123.site/v1'
});
// url: 'https://tchdriver.dev123.site/v1'
// url: 'https://tch-driver.pcns.co.uk/v1'

// url: 'https://tchsalsa.dev123.site/v1'
// url: 'https://tch-salsa.pcns.co.uk/v1'
