
  import { IonApp, IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonRouterOutlet } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import MenuComponent from './components/MenuComponent.vue';

  export default defineComponent({
    components: { IonApp, IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonRouterOutlet, MenuComponent },
    data() {
      return {
        isOpen: true,
      };
    },
    methods: {
      setOpen(isOpen: boolean) {
        this.isOpen = isOpen;
      },
    },
  });
