import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import helper from '@/services/Helpers';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: () => import("@/views/SignInPage.vue"),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: "/create-an-account",
    name: "Create An Account",
    component: () => import("@/views/CreateAccountPage.vue"),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("@/views/ForgotPasswordPage.vue"),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: () => import("@/views/ChangePasswordPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/ProfilePage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/mileage",
    name: "Mileage",
    component: () => import("@/views/MileagePage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/find-my-nearest",
    name: "Find my Nearest",
    component: () => import("@/views/FindMyNearestPage.vue"),
  },
  {
    path: "/emergency-contacts",
    name: "Emergency Contacts",
    component: () => import("@/views/EmergencyContactsPage.vue"),
  },
  {
    path: "/driving-tips",
    name: "Driving Tips",
    component: () => import("@/views/DrivingTipsPage.vue"),
  },
  {
    path: "/driving-assistance",
    name: "Driving Assistance",
    component: () => import("@/views/DrivingAssistancePage.vue"),
  },
  {
    path: "/end-of-lease-checklist",
    name: "End of Lease Checklist",
    component: () => import("@/views/EndOfLeaseChecklistPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/buy-my-car",
    name: "Buy my Car",
    component: () => import("@/views/BuyMyCarPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/sell-my-car",
    name: "Sell my Car",
    component: () => import("@/views/SellMyCarPage.vue"),
  },
  {
    path: "/licence-checker",
    name: "Licence Checker",
    component: () => import("@/views/LicenceCheckerPage.vue"),
  },
  {
    path: "/report-an-accident",
    name: "Report an Accident",
    component: () => import("@/views/ReportAnAccidentPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/p11d",
    name: "P11D",
    component: () => import("@/views/P11dPage.vue"),
  },
  {
    path: "/tax-calculator",
    name: "Tax Calculator",
    component: () => import("@/views/TaxCalculatorPage.vue"),
  },
  {
    path: "/tax-calculator-more/:car",
    name: "Tax Calculator More",
    component: () => import("@/views/TaxCalculatorMorePage.vue"),
  },
  {
    path: "/driver-checklist",
    name: "Driver Checklist",
    component: () => import("@/views/DriverChecklistPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/trip-recorder",
    name: "Trip Recorder",
    component: () => import("@/views/TripRecorderPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/trip-recorder/manual-entry",
    name: "Trip Recorder Manual Entry",
    component: () => import("@/views/TripRecorderManualEntryPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/trip-recorder/edit-trips",
    name: "Trip Recorder Edit Trips",
    component: () => import("@/views/TripRecorderEditTripsPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/book-rental",
    name: "Book a Rental",
    component: () => import("@/views/BookRentalPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/book-rental-salsa",
    name: "Book a Rental",
    component: () => import("@/views/BookRentalSalsaPage.vue")
  },
  {
    path: "/contract-adjustment",
    name: "Contract Adjustment",
    component: () => import("@/views/ContractAdjustmentPage.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/find-my-nearest-search/:type",
    name: "Find my Nearest Search",
    component: () => import("@/views/FindMyNearestSearchPage.vue"),
  },
  {
    path: "/cms-page/:type",
    name: "Content",
    component: () => import("@/views/CmsPage.vue"),
  },
  {
    path: "/locations/:data",
    name: "Locations",
    component: () => import("@/views/LocationsPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth && !to.meta.requiresGuest) {
    next();

    return;
  }

  if (to.meta.requiresAuth) {
    if (helper.loggedIn) {
      next();

      return;
    }

    next({ name: "Sign In" });
  } else if (to.meta.requiresGuest) {
    if (!helper.loggedIn) {
      next();

      return;
    }

    next({ name: "home" });
  }
});

export default router;
