
  import {
      IonMenu, IonContent, IonList, IonItem, IonLabel, menuController
  } from "@ionic/vue";
  import helper from '@/services/Helpers';

  import { defineComponent } from 'vue';

  export default defineComponent({
    components: { IonMenu, IonContent, IonList, IonItem, IonLabel },
    data() {
      return {
        loggedIn: false
      }
    },
    methods: {
      menuNavigation(url: string) {
        menuController.close('app-menu');
        this.$router.push(url);
      }
    },
    created() {
      this.$watch(
        () => this.$route.params,
        () => {
          this.loggedIn = helper.loggedIn;
        },
        { immediate: true }
      )
    }
  });
