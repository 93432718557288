
  import { defineComponent } from "vue";
  import helper from '@/services/Helpers';

  export default defineComponent({
    components: {},
    data() {
      return {
        loggedIn: false
      }
    },
    methods: {
      navigate(url: string) {
        this.$router.push(url);
      }
    },
    created() {
      this.$watch(
        () => this.$route.params,
        async () => {
          this.loggedIn = helper.loggedIn;

          if (!this.loggedIn) {
            const response = await helper.attemptAutoLogin();

            if (response) {
              this.loggedIn = helper.loggedIn;
            }
          }
        },
        { immediate: true }
      )
    }
  });
